import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  IconButton,
  MenuItem,
  Paper,
  TextField
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import Done from '@material-ui/icons/Done'
import Downshift from 'downshift'

const styles = theme => ({
  container: {
    // flexGrow: 1,
    position: 'relative',
    marginRight: theme.spacing()
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(2),
    left: 0,
    right: 0,
    backgroundColor: '#DDD'
  },
  buttonRoot: {
    //borderWidth: '1.5px'
    // minWidth: 100
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  },
  visibleInput: {
    width: 'auto',
    fontSize: '.8rem'
  },
  hiddenInput: {
    visibility: 'hidden'
  },
  icon: {
    color: 'gray',
    fontSize: 'smaller',
    width: 14,
    height: 14
  },
  textField: {
    maxWidth: 70
  }
})

class DownshiftTextField extends React.Component {
  constructor (props) {
    super(props)
    this.state = { input: '', isEditable: false }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleToggleEditable = this.handleToggleEditable.bind(this)
    this.handlePressEnter = this.handlePressEnter.bind(this)
  }

  handleInputChange (event) {
    // console.log('handleInputChange', event.target.value)
    this.setState({ input: event.target.value })
  }

  handleChange (selection) {
    // console.log('handleChange', selection)
    this.props.onChange(selection)
    this.setState({ input: '', isEditable: false })
  }

  handlePressEnter (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.props.onChange(this.state.input)
      this.setState({ input: '', isEditable: false })
    }
  }

  handleToggleEditable () {
    const { input } = this.state
    if (input !== '') this.props.onChange(input)
    this.setState(state => ({ input: '', isEditable: !state.isEditable }))
  }

  render () {
    const { classes, currentOption, availableOptions } = this.props
    const { input, isEditable } = this.state
    return (
      <Downshift
        inputValue={input}
        onChange={this.handleChange}
        selectedItem={currentOption}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
          openMenu
        }) => (
          <div className={classes.container}>
            {renderInput(
              {
                classes,
                openMenu,
                handlePressEnter: this.handlePressEnter,
                InputProps: getInputProps({
                  onChange: this.handleInputChange,
                  // placeholder: currentOption,
                  startAdornment: (
                    <div>
                      {!isEditable && (
                        <Button
                          variant='outlined'
                          size='small'
                          onClick={this.handleToggleEditable}
                          classes={{
                            root: classes.buttonRoot,
                            label: classes.buttonLabel
                          }}
                        >
                          {currentOption}
                        </Button>
                      )}
                    </div>
                  ),
                  endAdornment: isEditable && (
                    <IconButton
                      color='primary'
                      onClick={this.handleToggleEditable}
                    >
                      {input === '' ? (
                        <Close className={classes.icon} />
                      ) : (
                        <Done className={classes.icon} />
                      )}
                    </IconButton>
                  )
                }),
                label: ''
              },
              isEditable
            )}
            {isOpen && isEditable ? (
              <Paper className={classes.paper} square>
                {getSuggestions(inputValue, availableOptions).map(
                  (suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem: selectedItem
                    })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  }
}

function renderInput (inputProps, isEditable) {
  const {
    InputProps,
    classes,
    openMenu,
    handlePressEnter,
    ref,
    ...other
  } = inputProps
  return (
    <TextField
      className={classes.textField}
      onKeyPress={handlePressEnter}
      InputProps={{
        onClick: isEditable ? openMenu : null,
        disabled: !isEditable,
        variant: 'filled',
        disableUnderline: true,
        inputRef: ref,
        classes: {
          input: isEditable ? classes.visibleInput : classes.hiddenInput
        },
        ...InputProps
      }}
      {...other}
    />
  )
}

function renderSuggestion ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) {
  if (selectedItem === suggestion) return false
  const isHighlighted = highlightedIndex === index
  return (
    <MenuItem
      {...itemProps}
      key={suggestion + index}
      selected={isHighlighted}
      component='div'
      dense
    >
      {suggestion}
    </MenuItem>
  )
}

function getSuggestions (value, suggestions) {
  const input = value.trim().toLowerCase()
  const inputLength = input.length
  let count = 0
  return inputLength === 0
    ? suggestions
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.slice(0, inputLength).toLowerCase() === input
        if (keep) count += 1
        return keep
      })
}

export default withStyles(styles)(DownshiftTextField)
