import { useEffect, useState } from 'react'
import useDelay from './useDelay'

export default function useCachedState (key, initial) {
  const [data, setData] = useState(() => {
    const cachedValue = window.sessionStorage.getItem(key)
    try {
      return cachedValue ? JSON.parse(cachedValue) : initial
    } catch (err) {
      // bad cache, kill it
      window.sessionStorage.removeItem(key)
      return initial
    }
  })
  const delay = useDelay()

  useEffect(() => {
    try {
      delay(() => window.sessionStorage.setItem(key, JSON.stringify(data)))
    } catch (err) {
      // silently ignore
    }
  }, [data, delay, key])

  return [data, setData]
}
