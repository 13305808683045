import { useCallback, useRef } from 'react'

export default function useDelay () {
  const delayTimer = useRef(null)
  const delay = useCallback(
    (func, timeout = 1000) => {
      if (delayTimer.current) {
        clearTimeout(delayTimer.current)
        delayTimer.current = null
      }
      delayTimer.current = setTimeout(func, timeout)
    },
    [delayTimer]
  )
  return delay
}
