import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default function AgentContextMenu (props) {
  const { top, left, agent, client, topology, onClose } = props

  async function handleRemove () {
    const ok = await client.unregisterAgent(agent)
    if (ok) delete topology[agent]
    onClose()
  }

  function get () {
    const unknown = {
      hostname: '<unknown>',
      version: null,
      status: '<unknown>'
    }
    if (!topology) return unknown
    const data = topology[agent]
    if (!data) return unknown
    const {
      hostname = '<unknown>',
      version = null,
      status = '<unknown>'
    } = data
    return { hostname, version, status }
  }

  return (
    <Menu
      open
      onClose={onClose}
      anchorReference='anchorPosition'
      anchorPosition={{ top, left }}
    >
      <MenuItem disabled>{`Hostname: ${get().hostname}`}</MenuItem>
      {get().version &&
        <MenuItem disabled>{`Version: ${get().version}`}</MenuItem>}
      {(get().status === 'offline') &&
        <MenuItem onClick={handleRemove}>Remove</MenuItem>}
    </Menu>
  )
}
