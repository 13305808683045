import React from 'react'
import { makeStyles } from '@material-ui/core'
import StaticFunction from './StaticFunction'

const useStyles = makeStyles(() => ({
  functionsContainer: {
    width: '100%'
  }
}))

function StaticFunctions (props) {
  const classes = useStyles()

  const { agent, className, functions, meta, client } = props

  return (
    <div className={classes.functionsContainer}>
      {functions.map(funcName => (
        <StaticFunction
          key={funcName}
          agent={agent}
          className={className}
          funcName={funcName}
          meta={meta && meta[funcName]}
          client={client}
        />
      ))}
    </div>
  )
}
export default StaticFunctions
