import React, { useEffect, useRef, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import StyledTreeItem from './StyledTreeItem'
import { useTopology } from '../TopologyProvider'
import AgentContextMenu from './AgentContextMenu'
import InstanceContextMenu from './InstanceContextMenu'

import AgentIcon from '@material-ui/icons/WebAsset'
import ClassIcon from '@material-ui/icons/Category'
import InstanceIcon from '@material-ui/icons/FiberManualRecord'
import FunctionIcon from '@material-ui/icons/Functions'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { CircularProgress } from '@material-ui/core'

const useTopologyTreeStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflow: 'auto'
  }
}))

export default function TopologyTree (props) {
  const {
    onAgentClicked,
    onClassClicked,
    onClassContextClicked,
    onInstanceClicked,
    onMemberFunctionClicked,
    onMemberFunctionContextClicked,
    onStaticFunctionClicked,
    onStaticFunctionContextClicked,
    showFunctions = false,
    executorData
  } = props
  const classes = useTopologyTreeStyles()
  const { topology, loading, client } = useTopology()
  const [agentContext, setAgentContext] = useState(null)
  const [instanceContext, setInstanceContext] = useState(null)
  const [futureInstances, setFutureInstances] = useState([])
  const topologyRef = useRef(topology)
  const filterCallback = useCallback(filterFutureInstances, [topologyRef])

  // keep ref in sync
  useEffect(() => {
    topologyRef.current = topology
    setFutureInstances(prev => filterCallback(prev))
  }, [topology, filterCallback])

  useEffect(() => {
    if (!executorData) return
    const tmp = []
    for (const [id, { inputItems, address, functionType }] of Object.entries(
      executorData
    )) {
      if (functionType !== 'ctor') continue
      const [ak, ck] = address
      const item = inputItems.find(({ type }) => type === 'instance')
      if (!item || !item.config || !item.config.value) continue
      tmp.push({ ak, ck, id, ik: item.config.value })
    }
    setFutureInstances(filterCallback(tmp))
  }, [executorData, filterCallback])

  if (loading) return <CircularProgress />

  function filterFutureInstances (instances) {
    const obj = {}
    for (const [ak, av] of Object.entries(topologyRef.current)) {
      if (ak !== 'vrpc-live-backend' && av.status === 'online' && av.classes) {
        for (const [ck, cv] of Object.entries(av.classes)) {
          cv.instances.forEach(ik => {
            obj[ik] = { ak, ck }
          })
        }
      }
    }
    return instances.map(x => {
      const { ik, ak, ck } = x
      if (obj[ik] && obj[ik].ak === ak && obj[ik].ck === ck) {
        return { ...x, isOnline: true }
      }
      return { ...x, isOnline: false }
    })
  }

  function handleAgentContextClick (ev, ak) {
    ev.preventDefault()
    setAgentContext({ ak, left: ev.clientX, top: ev.clientY })
  }

  function handleAgentContextClose () {
    setAgentContext(null)
  }

  function handleInstanceContextClick (ev, ak, ck, ik) {
    ev.preventDefault()
    ev.stopPropagation()
    setInstanceContext({ ak, ck, ik, left: ev.clientX, top: ev.clientY })
  }

  function handleInstanceContextClose () {
    setInstanceContext(null)
  }

  return (
    <>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        {Object.keys(topology).map(ak => {
          // comment this for debugging the backend
          if (ak.startsWith('vrpc-live') || ak.startsWith('vrpc-app')) {
            return null
          }
          const av = topology[ak]
          return (
            <StyledTreeItem
              key={ak}
              nodeId={ak}
              labelText={ak}
              labelIcon={AgentIcon}
              status={av.status}
              // labelInfo={av.hostname}
              onClick={() => onAgentClicked && onAgentClicked(ak)}
              onContextMenu={ev => handleAgentContextClick(ev, ak)}
            >
              {av.classes &&
                Object.keys(av.classes).map(ck => {
                  const cv = av.classes[ck]
                  return (
                    <StyledTreeItem
                      key={`${ak}::${ck}`}
                      nodeId={`${ak}::${ck}`}
                      labelText={ck}
                      status={av.status}
                      labelIcon={ClassIcon}
                      onClick={() => onClassClicked && onClassClicked(ak, ck)}
                      onContextMenu={ev =>
                        onClassContextClicked && onClassContextClicked(ev, ck)
                      }
                    >
                      {showFunctions &&
                        cv.staticFunctions &&
                        cv.staticFunctions.map(sk => {
                          const pos = sk.indexOf('-')
                          const csk = pos === -1 ? sk : sk.substring(0, pos)
                          return (
                            <StyledTreeItem
                              key={`${ak}::${ck}::${csk}`}
                              nodeId={`${ak}::${ck}::${csk}`}
                              isFunction
                              status={av.status}
                              labelText={csk}
                              labelIcon={FunctionIcon}
                              onClick={() =>
                                onStaticFunctionClicked &&
                                onStaticFunctionClicked(ak, ck, csk)
                              }
                              onContextMenu={ev =>
                                onStaticFunctionContextClicked &&
                                onStaticFunctionContextClicked(ev, csk)
                              }
                            />
                          )
                        })}
                      {cv.instances &&
                        cv.instances.map(ik => {
                          return (
                            <StyledTreeItem
                              key={`${ak}::${ck}::${ik}`}
                              nodeId={`${ak}::${ck}::${ik}`}
                              labelText={ik}
                              status={av.status}
                              labelIcon={InstanceIcon}
                              onClick={() =>
                                onInstanceClicked &&
                                onInstanceClicked(ak, ck, ik)
                              }
                              onContextMenu={ev =>
                                handleInstanceContextClick(ev, ak, ck, ik)
                              }
                            >
                              {showFunctions &&
                                cv.memberFunctions &&
                                cv.memberFunctions.map(mk => {
                                  const pos = mk.indexOf('-')
                                  const cmk =
                                    pos === -1 ? mk : mk.substring(0, pos)
                                  return (
                                    <StyledTreeItem
                                      key={`${ak}::${ck}::${ik}::${cmk}`}
                                      nodeId={`${ak}::${ck}::${ik}::${cmk}`}
                                      isFunction
                                      status={av.status}
                                      labelText={cmk}
                                      labelIcon={FunctionIcon}
                                      onClick={() =>
                                        onMemberFunctionClicked &&
                                        onMemberFunctionClicked(ak, ck, ik, cmk)
                                      }
                                      onContextMenu={ev =>
                                        onMemberFunctionContextClicked &&
                                        onMemberFunctionContextClicked(ev, cmk)
                                      }
                                    />
                                  )
                                })}
                            </StyledTreeItem>
                          )
                        })}
                      {showFunctions &&
                        futureInstances
                          .filter(
                            x => x.ak === ak && x.ck === ck && !x.isOnline
                          )
                          .map(({ ik, id }) => (
                            <StyledTreeItem
                              key={`${ak}::${ck}::${id}`}
                              nodeId={`${ak}::${ck}::${id}`}
                              labelText={ik}
                              status={av.status}
                              futureInstance={ik}
                              labelIcon={InstanceIcon}
                              onClick={() =>
                                onInstanceClicked &&
                                onInstanceClicked(ak, ck, ik)
                              }
                              onContextMenu={ev =>
                                handleInstanceContextClick(ev, ak, ck, ik)
                              }
                            >
                              {cv.memberFunctions &&
                                cv.memberFunctions.map(mk => {
                                  return (
                                    <StyledTreeItem
                                      key={`${ak}::${ck}::${id}::${mk}`}
                                      nodeId={`${ak}::${ck}::${id}::${mk}`}
                                      isFunction
                                      futureInstance={ik}
                                      status={av.status}
                                      labelText={mk}
                                      labelIcon={FunctionIcon}
                                      onClick={() =>
                                        onMemberFunctionClicked &&
                                        onMemberFunctionClicked(ak, ck, ik, mk)
                                      }
                                      onContextMenu={ev =>
                                        onMemberFunctionContextClicked &&
                                        onMemberFunctionContextClicked(ev, mk)
                                      }
                                    />
                                  )
                                })}
                            </StyledTreeItem>
                          ))}
                    </StyledTreeItem>
                  )
                })}
            </StyledTreeItem>
          )
        })}
      </TreeView>
      {agentContext && (
        <AgentContextMenu
          top={agentContext.top}
          left={agentContext.left}
          agent={agentContext.ak}
          topology={topology}
          client={client}
          onClose={handleAgentContextClose}
        />
      )}
      {instanceContext && (
        <InstanceContextMenu
          top={instanceContext.top}
          left={instanceContext.left}
          agent={instanceContext.ak}
          className={instanceContext.ck}
          instance={instanceContext.ik}
          topology={topology}
          client={client}
          onClose={handleInstanceContextClose}
        />
      )}
    </>
  )
}
