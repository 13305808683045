import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress, Typography } from '@material-ui/core'
import ReactJson from 'react-json-view'

const styles = makeStyles(() => ({
  returnValue: {
    minWidth: 40,
    fontWeight: 450
  }
}))

function FunctionReturn (props) {
  const classes = styles()
  const { ret, state } = props
  const color = state === 'error' ? 'red' : '#81d695'

  if (state === 'busy') {
    return <LinearProgress variant='query' />
  }
  const type = typeof ret
  switch (type) {
    case 'undefined':
      return <Typography />
    case 'boolean':
      return (
        <Typography
          variant='body2'
          className={classes.returnValue}
          style={{ color }}
        >
          {ret ? 'true' : 'false'}
        </Typography>
      )
    case 'object':
      if (ret === null) return <Typography />
      return (
        <ReactJson
          collapsed={2}
          indentWidth={2}
          groupArraysAfterLength={10}
          name={false}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
          src={ret}
        />
      )
    case 'string': {
      if (ret.startsWith('iVBORw0KGgo')) {
        return (
          <img
            style={{ maxWidth: 512 }}
            src={`data:image/jpeg;base64,${ret}`}
            alt='jpeg'
          />
        )
      }
      if (ret.startsWith('PD94bWwgdm')) {
        return (
          <img
            style={{ maxWidth: 512 }}
            src={`data:image/svg+xml;base64,${ret}`}
            alt='svg'
          />
        )
      }
      return (
        <Typography
          variant='body2'
          className={classes.returnValue}
          style={{ color }}
        >
          {ret}
        </Typography>
      )
    }
    default:
      return (
        <Typography
          variant='body2'
          className={classes.returnValue}
          style={{ color }}
        >
          {ret}
        </Typography>
      )
  }
}
export default FunctionReturn
