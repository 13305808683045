import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import StaticFunctions from './StaticFunctions'
import MemberFunctions from './MemberFunctions'
import { useTopology } from './TopologyProvider'
import { TopologyTree } from './topologyTree'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  navigation: {
    minWidth: 400,
    padding: theme.spacing(),
    margin: theme.spacing(),
    flexGrow: 1,
  },
  functions: {
    padding: theme.spacing(),
    margin: theme.spacing(),
    flexGrow: 1
  }
}))

export default function RemoteControl () {
  const classes = useStyles()
  const [show, setShow] = useState()
  const [functions, setFunctions] = useState([])
  const [meta, setMeta] = useState({})
  const [address, setAddress] = useState({})
  const [proxy, setProxy] = useState()
  const { client, topology } = useTopology()
  useEffect(() => {
    const { agent, className } = address
    if (show === 'class') {
      setFunctions(topology[agent].classes[className].staticFunctions)
      setMeta(topology[agent].classes[className].meta)
    } else if (show === 'instance') {
      setFunctions(topology[agent].classes[className].memberFunctions)
      setMeta(topology[agent].classes[className].meta)
    }
  }, [show, address, topology])

  function handleAgentClicked (agent) {
    setAddress({ agent })
    setShow('agent')
  }

  function handleClassClicked (agent, className) {
    setAddress({ agent, className })
    setShow('class')
  }

  function handleInstanceClicked (agent, className, instance) {
    setAddress({ agent, className, instance })
    client.getInstance(instance).then(proxy => {
      setProxy(proxy)
      setShow('instance')
    })
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.navigation}>
        <TopologyTree
          onAgentClicked={handleAgentClicked}
          // onAgentContextClicked={handleAgentContextClicked}
          onClassClicked={handleClassClicked}
          onInstanceClicked={handleInstanceClicked}
          // onInstanceContextClicked={handleInstanceContextClicked}
        />
      </Paper>
      {show === 'class' && (
        <Paper className={classes.functions}>
          <StaticFunctions
            agent={address.agent}
            className={address.className}
            functions={functions}
            meta={meta}
            client={client}
          />
        </Paper>
      )}
      {show === 'instance' && (
        <Paper className={classes.functions}>
          <MemberFunctions
            agent={address.agent}
            className={address.className}
            instance={address.instance}
            functions={functions}
            meta={meta}
            proxy={proxy}
          />
        </Paper>
      )}
    </div>
  )
}
