import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  alpha,
  AppBar,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core'
import DomainIcon from '@material-ui/icons/Domain'
import SelectIcon from '@material-ui/icons/Check'
import RemoteControl from './RemoteControl'
import { TopologyProvider } from './TopologyProvider'

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      boxSizing: 'border-box'
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit'
    },
    body: {
      margin: 0,
      width: '100%',
      height: '100vh',
      backgroundColor: '#FFFFFF',
      color: theme.palette.text.primary,
      lineHeight: '1.2',
      overflowX: 'hidden',
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale' // Antialiasing.
    },
    a: {
      color: 'darkblue'
    }
  },
  root: {
  },
  title: {
    marginLeft: theme.spacing()
  },
  grow: {
    flexGrow: 1
  },
  domainSelect: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  domainTextField: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.2),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.3)
    },
    maxWidth: 180,
    paddingLeft: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      width: 'auto'
    }
  },
  input: {
    color: theme.palette.secondary.main,
    fontWeight: 600
  }
}))

export default function App () {
  const classes = useStyles()
  const [domain, setDomain] = useState('')
  const [activeDomain, setActiveDomain] = useState('')

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <Typography color='secondary' className={classes.title} variant='h6'>
            VRPC - LIVE
          </Typography>
          <div className={classes.grow} />
          <div className={classes.domainSelect}>
            <TextField
              className={classes.domainTextField}
              id='domain-input'
              placeholder='Select Domain...'
              onChange={({ target: { value } }) => setDomain(value)}
              value={domain}
              InputProps={{
                classes: { input: classes.input },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <DomainIcon color='secondary' />
                  </InputAdornment>
                )
              }}
            />
            <IconButton
              color='secondary'
              disabled={activeDomain === domain}
              onClick={() => setActiveDomain(domain)}
            >
              <SelectIcon
                style={{
                  color: activeDomain === domain ? '#e8ddb055' : '#e8ddb0'
                }}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {activeDomain !== '' && activeDomain === domain && (
        <TopologyProvider domain={activeDomain}>
          <RemoteControl />
        </TopologyProvider>
      )}
    </div>
  )
}
