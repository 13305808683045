import React from 'react'
import { makeStyles } from '@material-ui/core'
import MemberFunction from './MemberFunction'

const styles = makeStyles(() => ({
  functionsContainer: {
  }
}))

export default function MemberFunctions ({
  agent,
  className,
  instance,
  functions,
  meta,
  proxy
}) {
  const classes = styles()
  const cleanFuncs = functions.filter(x => x !== 'constructor')
  return (
    <div className={classes.functionsContainer}>
      {cleanFuncs.map(funcName => (
        <MemberFunction
          key={funcName}
          agent={agent}
          className={className}
          instance={instance}
          funcName={funcName}
          meta={meta && meta[funcName]}
          proxy={proxy}
        />
      ))}
    </div>
  )
}
