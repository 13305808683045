import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeItem from '@material-ui/lab/TreeItem'
import { Typography, useTheme } from '@material-ui/core'

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: 'var(--tree-view-bg-color, transparent)',
      color: 'var(--tree-view-color)'
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent'
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    // fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    borderRadius: 18
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    width: 16,
    height: 16
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    borderRadius: 10
  },
  specialText: {
    fontWeight: 'inherit',
    fontStyle: 'italic',
    flexGrow: 1
  },
  addIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing()
  },
  parentNode: {
    '& ul li $content': {
      // child left padding
      paddingLeft: `calc(var(--node-depth) * ${theme.spacing(2)}px)`
    }
  }
}))

export default function StyledTreeItem (props) {
  const {
    children,
    depth = 0,
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    status,
    isFunction,
    futureInstance,
    onAddClick,
    nodeId,
    ...other
  } = props
  const { green, grey, yellow } = useTheme()
  const classes = useTreeItemStyles()

  function getIconColor () {
    switch (status) {
      case 'online':
        return futureInstance ? yellow : green
      case 'offline':
        return grey
      default:
        return grey
    }
  }

  function getLabelText () {
    if (labelText === '__createShared__') return <i>create</i>
    if (labelText.startsWith('__') && labelText.endsWith('__')) {
      return <i>{labelText.slice(2, -2)}</i>
    }
    return labelText
  }

  return (
    <TreeItem
      nodeId={nodeId}
      className={props.children ? classes.parentNode : undefined}
      label={
        <div
          className={classes.labelRoot}
          ref={isFunction}
        >
          <LabelIcon
            style={{ color: getIconColor() }}
            color='inherit'
            className={classes.labelIcon}
          />
          <Typography variant='body2' className={classes.labelText}>
            {getLabelText()}
          </Typography>
          <Typography variant='caption' color='inherit'>
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
        '--node-depth': depth
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    >
      {React.Children.map(children, child => {
        if (!child) return null
        return React.cloneElement(child, { depth: depth + 1 })
      })}
    </TreeItem>
  )
}
