import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default function InstanceContextMenu (props) {
  const {
    top,
    left,
    client,
    instance,
    onClose
  } = props

  async function handleRemove () {
    await client.delete(instance)
    onClose()
  }

  return (
    <Menu
      open
      onClose={onClose}
      anchorReference='anchorPosition'
      anchorPosition={{ top, left }}
    >
      <MenuItem onClick={handleRemove}>Remove</MenuItem>
    </Menu>
  )
}
