import React, { useContext } from 'react'
import { VrpcClient } from 'vrpc'

const TopologyContext = React.createContext()

export class TopologyProvider extends React.Component {
  constructor ({ domain }) {
    super()
    const broker = `wss://${process.env.REACT_APP_BROKER_HOST}/mqtt`
    const client = new VrpcClient({ domain, broker })
    this.state = {
      client,
      topology: {},
      loading: true
    }
  }

  async componentDidMount () {
    const { client } = this.state
    try {
      await client.connect()
      client.on('agent', ({ agent, status, hostname, version }) => {
        const topologyUpdate = {
          [agent]: {
            status,
            hostname,
            version
          }
        }
        const topology = JSON.parse(JSON.stringify(this.state.topology))
        this.assignDeep(topology, topologyUpdate)
        this.setState({ topology })
      })
      client.on('class', (info) => {
        const {
          agent,
          className,
          instances,
          staticFunctions,
          memberFunctions,
          meta
        } = info
        const topologyUpdate = {
          [agent]: {
            classes: {
              [className]: {
                instances,
                staticFunctions: this.sanitizeStaticFunctions(staticFunctions),
                memberFunctions: this.sanitizeMemberFunctions(memberFunctions),
                meta
              }
            }
          }
        }
        const topology = JSON.parse(JSON.stringify(this.state.topology))
        this.assignDeep(topology, topologyUpdate)
        this.setState({ topology })
      })
      this.setState({ loading: false })
    } catch (err) {
      await client.end()
    }
  }

  sanitizeStaticFunctions (staticFunctions) {
    const ctor = staticFunctions.filter(x => x.startsWith('__createShared'))
    const dtor = staticFunctions.filter(x => x.startsWith('__delete'))
    const sanitized = staticFunctions.filter(x => !x.startsWith('__'))
    return [...ctor, ...dtor, ...sanitized]
  }

  sanitizeMemberFunctions (memberFunctions) {
    return memberFunctions.filter(x => x !== 'constructor')
  }

  assignDeep (t, s) {
    Object.keys(s).forEach(k => {
      if (!!s[k] && s[k].constructor === Object) {
        if (!t[k]) t[k] = {}
        this.assignDeep(t[k], s[k])
      } else {
        t[k] = s[k]
      }
    })
    return t
  }

  render () {
    const { children } = this.props
    return (
      <TopologyContext.Provider value={this.state}>
        {children}
      </TopologyContext.Provider>
    )
  }
}

export function useTopology () {
  return useContext(TopologyContext)
}
