import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import ReturnIcon from '@material-ui/icons/KeyboardReturn'
import HtmlTooltip from './HtmlTooltip'
import DownshiftTextField from './DownshiftTextField'
import { hri } from 'human-readable-ids'
import { nanoid } from 'nanoid'

const styles = makeStyles(theme => ({
  argumentText: {
    fontSize: 14,
    color: '#638ca8',
    fontWeight: 'bold'
  },
  brackets: {
    color: 'lightgrey'
  },
  create: {
    display: 'flex'
  }
}))

function FunctionArgsCtor (props) {
  const [cursorPos, setCursorPos] = useState(0)
  const [isMouseOver, setMouseOver] = useState(false)
  const [instanceId, setInstanceId] = useState('rand()')

  const classes = styles()
  const {
    funcName,
    params,
    ret,
    onArgsChange,
    onStateChange,
    onCall,
    args,
    state
  } = props

  let color
  switch (state) {
    case 'idle':
      color = 'grey'
      break
    case 'error':
      color = 'red'
      break
    case 'success':
      color = '#81d695'
      break
    case 'argChanged':
      color = 'orange'
      break
    default:
      color = 'grey'
  }

  let explicitTypes = []
  let [, types] = funcName.split('-')
  if (types) {
    const possibleTypes = ['string', 'object', 'array', 'boolean', 'number']
    possibleTypes.forEach((type) => {
      types = types.replace(RegExp(type, 'gi'), `${type}, `)
    })
    types = types.substring(6, types.length - 2) // skip the first string (id)
    explicitTypes = types.split(',').map(x => x.trim())
  }
  if (params) {
    if (params.length > 0) {
      types = params.map(({ name }) => name).join(', ')
    } else if (!types) {
      types = 'void'
    }
  }
  if (!types) types = ''

  function handleChange ({ target: { selectionStart, id, value } }) {
    onArgsChange(value)
    onStateChange('argChanged')
    setCursorPos(selectionStart)
  }

  function getTooltipTitle () {
    if (!ret && !params) return ''
    let idx
    if (isMouseOver) idx = -1
    else idx = args ? args.slice(0, cursorPos).split(',').length - 1 : 0
    let argSig
    if (!params) argSig = ''
    else {
      argSig = params.map(({ name, type, optional, default: defaultValue }, i) => {
        if (!type) type = explicitTypes[i + 1]
        let suffix = ''
        if (optional) {
          if (defaultValue) suffix = `=${defaultValue}`
          else suffix = '?'
        }
        const sig = (
          <span key={`${i}`}>
            {`${name}${suffix}: `}
            <span style={{ color: '#638ca8' }}>{type}</span>
            {i < params.length - 1 ? ', ' : ''}
          </span>
        )

        return i === idx ? <b key={i}>{sig}</b> : sig
      })
    }
    const retSig = ret ? ret.type : 'returns an instance of this class'
    if (idx > -1) {
      return (
        <>
          <Typography>
            ( {argSig} {'): '}
            <span style={{ color: '#638ca8' }}>{retSig}</span><br />
            {params && params[idx] && params[idx].description}
          </Typography>
        </>
      )
    }
    if (idx === -1) {
      return (
        <>
          <Typography>
            ( {argSig} {'): '}
            <b><span style={{ color: '#638ca8' }}>{retSig}</span></b><br />
            {ret && ret.description}
          </Typography>
        </>
      )
    }
    return ''
  }

  function beforeCall () {
    switch (instanceId) {
      case 'rand()':
        onCall(hri.random())
        break
      case 'uid()':
        onCall(nanoid())
        break
      case 'time()':
        onCall(Date.now().toString())
        break
      default:
        onCall(instanceId.toString())
    }
  }

  function computeWidth () {
    if (!args && !types) return 80
    if (!args) {
      return 80 + types.length * 6.5
    }
    return 80 + args.length * 6.5
  }

  return (
    <div className={classes.create}>
      <HtmlTooltip placement='top' title='Let generate, or define instance id'>
        <DownshiftTextField
          onChange={(value) => setInstanceId(value)}
          availableOptions={['rand()', 'uid()', 'time()']}
          currentOption={instanceId}
        />
      </HtmlTooltip>
      <HtmlTooltip placement='top' title={getTooltipTitle()}>
        <TextField
          style={{ width: computeWidth() }}
          id={funcName}
          value={args}
          placeholder={types}
          disabled={types === 'void'}
          onChange={(e) => handleChange(e)}
          onFocus={() => setMouseOver(false)}
          onKeyPress={({ key }) => key === 'Enter' ? beforeCall() : null}
          InputProps={{
            classes: {
              input: classes.argumentText
            },
            disableUnderline: true,
            startAdornment:
              (
                <InputAdornment position='start'>
                  <Typography
                    className={classes.brackets}
                  >
                    (
                  </Typography>
                </InputAdornment>
              ),
            endAdornment:
              (
                <InputAdornment position='end'>
                  <Typography
                    className={classes.brackets}
                  >
                    )
                  </Typography>
                  <IconButton
                    aria-label='Return'
                    onClick={() => beforeCall()}
                    onMouseOver={() => setMouseOver(true)}
                    onMouseLeave={() => setMouseOver(false)}
                  >
                    <ReturnIcon style={{ color, width: 14, height: 14 }} />
                  </IconButton>
                </InputAdornment>
              )
          }}
        />
      </HtmlTooltip>
    </div>
  )
}
export default FunctionArgsCtor
