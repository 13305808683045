import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 800,
    border: '1px solid #bbb'
  }
}))(Tooltip)

export default HtmlTooltip
