import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import ReactJson from 'react-json-view'

const styles = makeStyles(theme => ({
  callback: {
    display: 'flex',
    flexDirection: 'row'
  },
  callbackLabel: {
    fontSize: 14,
    color: 'grey',
    marginRight: theme.spacing(1)
  },
  callbackValue: {
    fontWeight: '450',
    fontSize: 14
  }
}))

function FunctionCallback ({ cb }) {
  const classes = styles()
  return Object.keys(cb).map(x => {
    let value = cb[x]
    if (typeof value === 'boolean') value = value ? 'true' : 'false'
    if (value === '__vrpc::not-serializable__') value = '[skipped]'
    return (
      <div key={x} className={classes.callback}>
        <Typography className={classes.callbackLabel}>{`${x}:`}</Typography>
        {typeof value === 'object' ? (
          <ReactJson
            collapsed={2}
            indentWidth={2}
            groupArraysAfterLength={5}
            name={false}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            src={value}
          />
        ) : (
          <Typography className={classes.callbackValue}>{value}</Typography>
        )}
      </div>
    )
  })
}
export default FunctionCallback
