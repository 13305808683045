import React from 'react'
import { ClickAwayListener, Typography } from '@material-ui/core'
import HtmlTooltip from './HtmlTooltip'

function FunctionName ({ funcName, description }) {
  const [open, setOpen] = React.useState(false)
  let [label] = funcName.split('-')
  let highlight = false
  if (label.endsWith('__')) {
    const offset = label.startsWith('__') ? 2 : 0
    label = label.substring(offset, label.length - 2)
    if (label === 'createNamed') label = 'create'
    highlight = true
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <HtmlTooltip
          title={
            <Typography
              style={{ fontSize: 11 }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          }
          placement='bottom'
          onClose={() => setOpen(false)}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Typography variant='body2' onClick={() => setOpen(true)}>
            {highlight ? <i>{label}</i> : label}
          </Typography>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
}
export default FunctionName
