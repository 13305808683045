import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import App from './App'
import reportWebVitals from './reportWebVitals'

const vrpcTheme = createTheme({
  typography: {
    fontSize: 14
  },
  palette: {
    type: 'light',
    primary: {
      main: '#424242',
    },
    secondary: {
      main: '#e8ddb0',
    }
  },
  overrides: {
    MuiExpansionPanelDetails: {
      root: {
        padding: '8px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 16
      }
    }
  },
  gray: '#6d6d6d',
  green: '#62a378',
  orange: '#C77B3C',
  blue: '#628DA3',
  yellow: '#C6AB3C',
  brown: '#A37762',
  violet: '#A3628D',
  red: '#C73C3D'
})

ReactDOM.render(
  <MuiThemeProvider theme={vrpcTheme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
