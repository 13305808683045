import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import yaml from 'js-yaml'
import useCachedState from './useCachedState'
import FunctionName from './FunctionName'
import FunctionArguments from './FunctionArguments'
import FunctionReturn from './FunctionReturn'
import FunctionCallback from './FunctionCallback'
import EventEmitter from 'events'

const useStyles = makeStyles(theme => ({
  function: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2)
  },
  label: {
    minWidth: 120,
    marginBottom: theme.spacing()
  },
  interactivePart: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 400
  },
  arguments: {},
  trigger: {},
  return: {},
  callbacks: {}
}))

export default function MemberFunction ({
  agent,
  className,
  instance,
  funcName,
  meta,
  proxy
}) {
  const classes = useStyles()
  const eventEmitter = useRef(new EventEmitter())
  const cacheKeyRoot = `${agent}:${className}:${instance}:${funcName}`
  const [lvcArgs, setLvcArgs] = useCachedState(`${cacheKeyRoot}:args`)
  const [lvcRet, setLvcRet] = useCachedState(`${cacheKeyRoot}:ret`)
  const [lvcCb, setLvcCb] = useCachedState(`${cacheKeyRoot}:cb`, {})
  const [lvcState, setLvcState] = useCachedState(`${cacheKeyRoot}:state`)

  const defaults = extractDefaults()

  async function handleCall () {
    setLvcState('busy')
    setLvcCb({})
    try {
      const parsedArgs = parseArgs()
      if (parsedArgs.length < defaults.length) {
        parsedArgs.push(...defaults.slice(parsedArgs.length))
      }
      const pos = funcName.indexOf('-')
      const cleanFuncName = pos === -1 ? funcName : funcName.substring(0, pos)
      const val = await proxy[cleanFuncName](...parsedArgs)
      setLvcState('success')
      setLvcRet(val)
    } catch (err) {
      setLvcState('error')
      setLvcRet(err.message)
    }
  }

  function parseArgs () {
    if (!lvcArgs) return []
    const json = yaml.safeLoad(`[${lvcArgs}]`)
    const result = []
    const evArgs = ''
    const cbArgs = ''
    json.forEach(arg => {
      if (couldParseAsEvent(arg, evArgs, result)) return
      if (couldParseAsCallback(arg, cbArgs, result)) return
      result.push(arg)
    })
    return result
  }

  function couldParseAsEvent (arg, evArgs, result) {
    if (typeof arg === 'string' && evArgs === '' && arg.startsWith('((')) {
      // single argument, e.g. `((handler))`
      if (arg.endsWith('))')) {
        evArgs = `[${arg.substring(2, arg.length - 2)}]`
        result.push(createEventHandler(evArgs))
        evArgs = ''
        return true
      }
      // first argument of more to come
      evArgs = `[${arg.substr(2)}`
      return true
    }
    // another arg with more to come
    if (evArgs !== '' && !arg.endsWith('))')) {
      evArgs = `${evArgs},${arg}`
      return true
    }
    // last arg of multi-arg event
    if (evArgs !== '' && arg.endsWith('))')) {
      evArgs = `${evArgs},${arg.substr(0, arg.length - 2)}]`
      result.push(createEventHandler(evArgs))
      evArgs = ''
      return true
    }
    return false
  }

  function couldParseAsCallback (arg, cbArgs, result) {
    if (typeof arg === 'string' && cbArgs === '' && arg.startsWith('(')) {
      if (arg.endsWith(')')) {
        cbArgs = `[${arg.substring(1, arg.length - 1)}]`
        result.push(createCallback(cbArgs))
        cbArgs = ''
        return true
      }
      cbArgs = `[${arg.substr(1)}`
      return true
    }
    if (cbArgs !== '' && !arg.endsWith(')')) {
      cbArgs = `${cbArgs},${arg}`
      return true
    }
    if (cbArgs !== '' && arg.endsWith(')')) {
      cbArgs = `${cbArgs},${arg.substr(0, arg.length - 1)}]`
      result.push(createCallback(cbArgs))
      cbArgs = ''
      return true
    }
    return false
  }

  function extractDefaults () {
    if (meta && meta.params) {
      return meta.params.map(({ default: dv }) => dv)
    }
    return []
  }

  function createCallback (cbArgs) {
    const cbArgsJson = yaml.safeLoad(cbArgs)
    return (...innerArgs) => {
      setLvcCb(prev => {
        const next = { ...prev }
        cbArgsJson.forEach((y, i) => {
          next[y] = innerArgs[i]
        })
        return next
      })
    }
  }

  function createEventHandler (evArgs) {
    const evArgsJson = yaml.safeLoad(evArgs)
    eventEmitter.current.on('event', (...innerArgs) => {
      setLvcCb(prev => {
        const next = { ...prev }
        evArgsJson.forEach((y, i) => {
          next[y] = innerArgs[i]
        })
        return next
      })
    })
    return { emitter: eventEmitter.current, event: 'event' }
  }

  return (
    <div className={classes.function}>
      <div className={classes.label}>
        <FunctionName
          funcName={funcName}
          description={meta && meta.description}
        />
      </div>
      <div className={classes.interactivePart}>
        <div className={classes.arguments}>
          <FunctionArguments
            funcName={funcName}
            params={meta && meta.params}
            ret={meta && meta.ret}
            onArgsChange={setLvcArgs}
            onStateChange={setLvcState}
            onCall={() => handleCall()}
            args={lvcArgs}
            state={lvcState}
          />
        </div>
        <div className={classes.return}>
          <FunctionReturn funcName={funcName} ret={lvcRet} state={lvcState} />
        </div>
        <div className={classes.callbacks}>
          <FunctionCallback funcName={funcName} cb={lvcCb} state={lvcState} />
        </div>
      </div>
    </div>
  )
}
